<template>
  <validation-observer ref="simpleRules">
    <b-card-code
      title="Form Disposisi"
      :class="$route.name == 'detail-disposisi' ? 'detail-dispo' : ''"
    >
      <b-button
        v-show="$route.name === 'detail-disposisi' && authorize"
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        style="position: absolute;right: 15px;top: -10px;"
        type="submit"
        @click.prevent="goEdit"
      >
        <span class="align-middle">Edit Disposisi</span>
      </b-button>
      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="No. Surat"
            rules="required"
          >
            <b-form-group
              label="No. Surat"
              label-for="NoSurat"
            >
              <b-form-input
                id="NoSurat"
                v-model="NoSurat"
                placeholder="Input No. Surat"
                disabled
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Deadline"
            label-for="Deadline"
          >
            <b-form-select
              v-model="Deadline"
              :options="optionsDeadline"
              placeholder="Pilih Deadline2"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Perihal"
            rules="required"
          >
            <b-form-group
              label="Perihal"
              label-for="Perihal"
            >
              <b-form-input
                id="Perihal"
                v-model="Perihal"
                placeholder="Input Perihal"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Kategori"
            rules="required"
          >
            <b-form-group
              label="Kategori"
              label-for="Kategori"
            >
              <b-form-select
                v-model="Kategori"
                :options="optionsKategori"
                placeholder="as"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Pengirim"
            rules="required"
          >
            <b-form-group
              label="Pengirim"
              label-for="Pengirim Surat"
            >
              <b-form-input
                id="Pengirim"
                v-model="Pengirim"
                placeholder="Input Pengirim Surat"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <b-form-group
            label="Keputusan"
            label-for="Keputusan"
            class="mt-1"
          >
            <v-select
              v-model="selectedKeputusan"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Pilih Keputusan"
              :options="optionsKeputusan"
              :disabled="$route.name !== 'edit-disposisi'"
            />
          </b-form-group>

          <b-form-group
            class="mt-2"
            label-for="dilihat"
            label="Divisi/ Bagian/Unit Terkait"
          >
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              multiple
              placeholder="Pilih Divisi"
              :options="options"
              :disabled="$route.name !== 'edit-disposisi'"
            />
          </b-form-group>

          <b-form-group
            label="Untuk Diperhatikan"
            label-for="dilihat"
            class="mt-2"
          >
            <v-select
              v-model="selectedViewers"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Pilih Viewers"
              :options="optionsViewers"
              :disabled="$route.name !== 'edit-disposisi'"
              multiple
            />
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="FileSurat"
            rules="required"
            class=" mt-2"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >

              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
                :disabled="$route.name !== 'edit-disposisi'"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <label for="textarea-default">Deskripsi</label>
          <b-form-textarea
            id="textarea-default"
            v-model="Deskripsi"
            placeholder="Textarea"
            rows="3"
          />

          <label
            for="textarea-default"
            class="mt-2"
          >Catatan</label>
          <b-form-textarea
            id="textarea-default"
            v-model="Catatan"
            placeholder="Textarea"
            rows="3"
          />

          <b-form-group
            label="Private"
            label-for="private"
            class="mt-2"
          >
            <b-form-checkbox
              v-model="privates"
              checked="false"
              name="check-button"
              :disabled="$route.name !== 'edit-disposisi'"
              switch
              inline
            />
          </b-form-group>

        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="12">
          <h5> File Surat </h5>
          <b-form-group
            label-for="FileSurat"
          >
            <b-row
              v-if="gantiFile == false && file !== null"
              class="match-height"
            >
              <b-col
                v-for="item in file"
                :key="item.id"
                md="6"
                class="fileSurat"
              >
                <div
                  class="open-file"
                  @click="openFile(item.url)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="56"
                  />
                  <h5 class="ml-1">
                    Open File
                    <span> {{ item.filename }} </span>
                  </h5>
                </div>
              </b-col>

              <b-button
                v-show="$route.name === 'edit-disposisi'"
                v-model="gantiFile"
                variant="outline-primary"
                class="bg-gradient-primary mt-1 ml-1"
                style="height: 29px"
                size="sm"
                @click.prevent="toggleFile"
              >
                <span class="align-middle">Ganti File</span>
              </b-button>
            </b-row>
            <div
              v-else
              style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;"
            >
              <b-form-file
                id="FileSurat"
                ref="file"
                v-model="file"
                type="file"
                placeholder="Input File Surat"
                drop-placeholder="Drop file here..."
                :disabled=" $route.name !== 'edit-disposisi'"
                multiple="multiple"
                @change="fileChange"
              />

              <b-button
                v-show="file !== null"
                v-model="gantiFile"
                variant="outline-primary"
                class="bg-gradient-primary mt-1"
                @click.prevent="toggleFile"
              >
                <span class="align-middle">Open File</span>
              </b-button>
            </div>

          </b-form-group>

        </b-col>
      </b-row>

      <b-button
        v-show="$route.name == 'edit-disposisi'"
        variant="outline-primary"
        class="bg-gradient-primary"
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button>

    </b-card-code>

    <b-card-code
      title="Komentar Disposisi"
      :class="$route.name == 'detail-disposisi' ? 'detail-dispo' : ''"
    >
      <b-row
        class="match-height"
        style="padding:0 30px"
      >
        <b-col
          md="12"
        >
          <h5 class="mb-1">
            Keputusan
          </h5>
          <div
            class="tanggapan"
          >
            <div class="avatar">
              <img :src="require('@/assets/images/icons/user.png')">
            </div>
            <div
              class="input"
              :class="userRole === selectedKeputusan.text ? '' : 'uncomment'"
            >
              <div class="komentar-title">
                <h2> {{ selectedKeputusan.text }} </h2>
                <span> {{ komentar1Date ? komentar1Date : '' }} </span>
              </div>
              <b-input-group>
                <div class="komentar">
                  <div class="left">
                    <b-form-input
                      v-model="Komentar1"
                      :disabled="userRole !== selectedKeputusan.text"
                      :placeholder="userRole !== selectedKeputusan.text ? 'Belum ada komentar' : 'Tulis Komentar Anda'"
                    />
                  </div>
                  <div class="right">
                    <b-button
                      v-show="userRole === selectedKeputusan.text"
                      variant="outline-primary"
                      class="bg-gradient-primary btn-sm"
                      style="font-size: 12px;height:unset"
                      @click="kirimKomentar1"
                    >
                      <span
                        class="align-middle"
                      > {{ userRole !== selectedKeputusan.text ? 'Tambah Komentar' : 'Update Komentar' }}</span>
                    </b-button>
                  </div>
                </div>
              </b-input-group>
            </div>
          </div>
        </b-col>

        <b-col
          md="12"
        >
          <h5 class=" mt-3 mb-1">
            Responder
          </h5>
          <b-row>
            <b-col
              v-for="(option, index) in Komentar"
              :key="option.id"
              md="6"
              class="tanggapan mb-2"
            >
              <div class="avatar">
                <img :src="require('@/assets/images/icons/user.png')">
              </div>
              <div
                class="input"
                :class="userRole === option.role.name ? '' : 'uncomment'"
              >
                <div class="komentar-title">
                  <h2> {{ option.role.name }}</h2>
                  <span> {{ dateFormat(option.updated_at) }} </span>
                </div>
                <b-input-group>
                  <div class="komentar">
                    <div class="left">
                      <b-form-input
                        v-model="Komentar[index].comment"
                        :placeholder="userRole === option.role.name ? 'Tambahkan Komtentar Anda' : 'Belum ada komentar'"
                        :disabled="userRole !== option.role.name"
                      />
                    </div>
                    <div class="right">
                      <b-button
                        v-show="userRole === option.role.name"
                        variant="outline-primary"
                        class="bg-gradient-primary btn-sm"
                        style="font-size: 12px;height:unset"
                        @click="kirimKomentar(index)"
                      >
                        <span
                          class="align-middle"
                        > {{ option.updated_at ? 'Update Komentar' : 'Tambah Komentar' }}</span>
                      </b-button>
                    </div>
                  </div>
                </b-input-group>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card-code>

    <b-card-code
      v-show="authorizeResponder"
      title="Responder Disposisi"
    >
      <b-row class="match-height">
        <b-col md="12">
          <b-form-group>
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              multiple
              :close-on-select="false"
              placeholder="Pilih Divisi"
              :options="options"
              style="width: 100%;"
            />
            <b-button
              variant="outline-primary"
              class="bg-gradient-primary btn-sm mt-1"
              style="font-size: 12px;height: 37px;"
            >
              Simpan
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-code>

    <b-row class="match-height">
      <b-col md="7">
        <b-card-code title="Perintah Disposisi">
          <v-select
            v-model="selectedPerintah"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :placeholder="$route.name == 'detail-disposisi' ? 'Belum ada tujuan Perintah Disposisi' : 'Pilih Tujuan Perintah Disposisi'"
            :options="optionsViewers"
            class="mb-2"
          />
          <b-button
            v-b-modal.modal-primary
            :disabled="selectedPerintah.length === 0"
            variant="outline-primary"
            class="bg-gradient-primary mt-2"
            type="submit"
          >

            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah</span>
          </b-button>
        </b-card-code>
      </b-col>

      <b-col md="5">
        <b-card-code
          title="Riwayat Disposisi"
        >
          history
        </b-card-code>
      </b-col>
    </b-row>

    <!-- modal -->
    <b-modal
      id="modal-primary"
      ok-title="Accept"
      modal-class="modal-primary"
      cancel-variant="outline-secondary"
      cancel-title="Cancel"
      centered
      title="Lanjutkan perintah disposisi"
    >
      <b-card-text>
        Berikan perintah disposisi kepada <b> {{ selectedPerintah.text }} </b>
      </b-card-text>
    </b-modal>
  </validation-observer>
</template>

<script>
/* eslint-disable no-unneeded-ternary */
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
  BButton, BRow, BCol, BFormFile, BFormTags, BFormCheckboxGroup, BFormTextarea, BInputGroup,
  BFormCheckbox, VBModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import axios from '@axios'
import dayjs from 'dayjs'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    BInputGroup,
    vSelect,
    BFormCheckbox,
    VBModal,
  },
  data() {
    return {
      NoSurat: '',
      Deadline: '',
      Perihal: '',
      Kategori: '',
      Pengirim: '',
      Deskripsi: '',
      Catatan: '',
      Perintah: '',
      Jabatan: '',
      Komentar1: '',
      komentar1Date: '',
      dispoID: '',
      Komentar: [],
      Responder1: '',
      userRole: '',
      selectedFile: '',
      selectedKeputusan: [],
      selectedPerintah: [],
      selectedViewers: [],
      privates: false,
      authorize: false,
      authorizeResponder: false,
      file: [],
      fileID: [],
      gantiFile: false,
      fileName: '',
      url: '',
      selected: [],
      options: [],
      userName: '',
      optionsResponder: [],
      optionsKeputusan: [],
      userData: [],
      optionsViewers: [
        { value: '', text: '' },
      ],
      tags: [],
      optionsDeadline: [],
      optionsKategori: [
        { value: '', text: 'Pilih Kategori' },
        { value: 'Undangan', text: 'Undangan' },
        { value: 'Permohonan', text: 'Permohonan' },
        { value: 'CSR', text: 'CSR' },
        { value: 'Instansi Pendidikan', text: 'Instansi Pendidikan' },
        { value: 'Pemberitahuan', text: 'Pemberitahuan' },
        { value: 'ILain-lain', text: 'ILain-lain' },
      ],
      optionsPerintah: [
        { value: '', text: 'Pilih Tujuan' },
        { value: '1', text: 'Manager Umum' },
        { value: '2', text: 'Kabag HRD' },
        { value: '3', text: 'Manajer Divisi Inti' },
        { value: '4', text: 'Kabag Keuangan' },
      ],
    }
  },
  created() {
    if (store.state.userData.permission.find(e => e === 'SIAP.Disposition.Code.Z')) {
      this.authorize = true
    } else {
      this.authorize = false
    }
    this.userRole = store.state.userData.role.name
  },
  mounted() {
    this.getResponder()
    this.getDetail()
    this.getViewer()
    this.getDecision()
  },
  methods: {
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      // this.fileName = data
      this.file = data
    },
    openFile(e) {
      window.open(e, '_blank')
    },
    goEdit() {
      window.location.href = `/disposisi/edit-disposisi/${this.$route.params.id}`
    },
    toggleFile() {
      if (this.gantiFile === false) {
        this.gantiFile = true
      } else {
        this.gantiFile = false
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.editDispo()
        }
      })
    },
    async kirimKomentar1() {
      await axios.post(`siap/dispo/comment/${this.$route.params.id}`, {
        comment: this.Komentar1,
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'InfoIcon',
              text: response.data.message,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.getDetail()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'InfoIcon',
              text: error.response,
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        })
    },
    dateFormat(time) {
      const formatted = dayjs(time).format('DD-MM-YYYY (HH:mm)')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },
    async kirimKomentar(n) {
      await axios.post(`siap/dispo/comment/${this.$route.params.id}`, {
        comment: this.Komentar[n].comment,
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'InfoIcon',
              text: response.data.message,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.getDetail()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'InfoIcon',
              text: error.response.data.error.message,
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        })
    },

    // async tujuanDisposisi() {
    //   await axios.post('api/v1/siap/disposition/send', {
    //     disposition_id: this.dispoID,
    //     to: this.selectedPerintah.value,
    //   }, {
    //     headers:
    //     { token: localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName) },
    //   })
    //     .then(response => {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Success',
    //           icon: 'InfoIcon',
    //           text: response.data.message,
    //           variant: 'success',
    //         },
    //       },
    //       {
    //         position: 'bottom-right',
    //       })
    //     })
    //     .catch(error => {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Error',
    //           icon: 'InfoIcon',
    //           text: error.response.data.error.message,
    //           variant: 'danger',
    //         },
    //       },
    //       {
    //         position: 'bottom-right',
    //       })
    //     })
    // },

    async getDetail() {
      const param = Number(this.$route.params.id)
      const { data } = await axios.get(`/siap/dispo/${param}`)
      this.NoSurat = data.code
      const date = dayjs(data.deadline.date).format('DD-MM-YYYY')
      this.optionsDeadline.push({ text: date, value: data.deadline.day })
      this.Deadline = data.deadline.day
      this.Perihal = data.title
      this.dispoID = data.id
      this.Pengirim = data.from
      this.Deskripsi = data.desc
      this.Catatan = data.note
      this.file = data.file
      this.Kategori = data.category.name
      this.Komentar1 = data.detail.decision.comment
      this.komentar1Date = data.detail.decision.updated_at === null ? '' : dayjs(data.detail.decision.updated_at).format('DD-MM-YYYY - HH:mm')
      this.Komentar = data.detail.responder
      this.selected = data.detail.responder.map(e => ({
        value: e.role.id, text: e.role.name, userID: e.user.id, receiverType: 'Responder',
      }))
      this.selectedViewers = data.detail.guest.map(e => ({
        value: e.role.id, text: e.role.name, userID: e.user.id, receiverType: 'Guest',
      }))
      this.selectedKeputusan = {
        value: data.detail.decision.role.id,
        text: data.detail.decision.role.name,
        userID: data.detail.decision.user.id,
        receiverType: 'Decision',
      }
      if (this.selected.find(e => e.userID === store.state.userData.id)) {
        this.authorizeResponder = true
      } else if (this.selectedKeputusan.userID === store.state.userData.id) {
        this.authorizeResponder = true
      } else {
        this.authorizeResponder = false
      }
      this.privates = data.is_private
      this.tags = data.tag.map(e => (e.name))
      // .catch(error => {
      //   console.log(error)
      // })
    },

    async getResponder() {
      const { data } = await axios.get('siap/dispo/receiver-type')
      this.options = data.map(e => ({
        value: e.role.id, text: e.role.name, userID: e.user.id, receiverType: 'Responder',
      }))
    },

    async getViewer() {
      const { data } = await axios.get('siap/dispo/receiver-type/all',
        {
          params:
          { hide_user_by_id: this.selectedKeputusan.value },
        })
      this.optionsViewers = data.map(e => ({
        value: e.role.id, text: e.role.name, userID: e.user.id, receiverType: 'Guest',
      }))
    },

    async getDecision() {
      const { data } = await axios.get('siap/dispo/receiver-type/decision')
      this.optionsKeputusan = data.map(e => ({
        value: e.role.id, text: e.role.name, userID: e.user.id, receiverType: 'Decision',
      }))
    },

    async editDispo() {
      const responder = this.selected.map(e => ({ user_id: e.userID, role_id: e.value, receiver_type: e.receiverType }))
      const viewers = this.selectedViewers.map(e => ({ user_id: e.userID, role_id: e.value, receiver_type: e.receiverType }))
      await axios.post(`siap/dispo/update/${this.dispoID}`, {
        title: this.Perihal,
        from: this.Pengirim,
        cat: this.Kategori,
        desc: this.Deskripsi,
        deadline: this.Deadline,
        note: this.Catatan,
        is_private: this.privates,
        file: this.file ? this.file.map(e => (e.id)) : undefined,
        tags: this.tags,
        send_to: viewers.concat(
          {
            user_id: this.selectedKeputusan.userID,
            role_id: this.selectedKeputusan.value,
            receiver_type: this.selectedKeputusan.receiverType,
          },
        ).concat(responder),
      })
        .then(response => {
        // console.log(response.data.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'InfoIcon',
              text: response.data.message,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'InfoIcon',
              text: error.response.data.error.message,
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
.row{
  margin-bottom: 20px;
}
.form-group{
  margin-bottom: 5px;
}
.nopad{
    padding: 0;
}
.labelfull{
  .custom-checkbox{
    width: 100%;
  }
}
.labelfull .custom-checkbox {
    width: 100%;
}

.detail-dispo{
  .form-control, .custom-select{
    border: unset;
    border-bottom: 1px solid #d8d6de;
    border-radius: 0;
    pointer-events: none;
  }
}

.fileSurat{
   display: flex;
  justify-content: space-between;
  align-items: center;
  span{
    color: #a6a6a6;
    display: block;
    margin-top: 5px;
    font-size: 12px;
  }
}

.tanggapan{
  display: flex;
  align-items: center;
  justify-content: space-around;
  .komentar-title{
    display: flex;
    align-items: baseline;
    span{
      font-size: 13px;
      margin-left: 10px ;
    }
  }
  .komentar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .left{
      flex-grow: 1;
      margin-right: 10px;
      span{
        font-size: 14px;
        display: block;
        flex-grow: 1;
        margin-right: 10px;
      }
    }
  }
  .uncomment{
    input{
      border: unset !important;
      // border-left: solid 1px !important;
      border-radius: 0 !important;
      padding-left: 0;
      background: #fff;
      color: #5e5873;
    }
  }
  input{
    border: 1px solid #d8d6de !important;
    pointer-events: unset !important;
  }
  .avatar{
    width: 80px;
    img{
      width: 100%;
      height: intrinsic;
    };
  }
  h2{
    font-size: 16px;
  }
  h3{
    font-size: 14px;
  }
  .input{
    margin-left: 25px;
    flex-grow: 1;
  }
  .input-group-append{
    cursor: pointer;
  }
  .input-group-text{
    background-image: linear-gradient(47deg, #00427A, #005ead);
    color: #fff;
  }
  .btn{
    height: 38px;
  }
}

[dir] .vs--disabled .vs__selected{
  background-color: #00427A;
  color: #fff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #615f6f;
}

.open-file{
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  transition: background-color 0.5s ease;
  &:hover{
    background: #efefef;
  }
}
</style>
